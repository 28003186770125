import { getOrCreateDataObject } from 'o365.vue.ts';
import Procedure from 'o365.modules.Procedure.ts';


export const deleteFilterPerson = new Procedure({
    id: "proc_o365_deleteFilterPerson",
    procedureName: "sstp_Database_FiltersPersons_Delete"
});

export const hideSharedFilter = new Procedure({
    id: "proc_o365_hideSharedFilter",
    procedureName: "sstp_Database_FiltersSharing_SetAsHidden"
});
export const unHideSharedFilter = new Procedure({
    id: "proc_o365_unHideSharedFilter",
    procedureName: "sstp_Database_FiltersSharing_RemoveHidden"
});
export function getSharedWithDO() {
    return getOrCreateDataObject({
        id: 'ds_o365_filters_sharedWith_',
        viewName: 'sviw_Database_FiltersPersons',
        uniqueTable: 'stbv_Database_FiltersPersons',
        selectFirstRowOnLoad: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [{ name: "PrimKey" }, { name: "ID", type: "number" }, { name: "Person_ID", type: "number" },
        { name: "Filter_ID", type: "number" },
        { name: "PersonName" }, { name: "Updated", type: "datetime" },
        { name: "Created", type: "datetime" }, { name: "UpdatedBy_ID" },
        { name: "CreatedBy_ID" }]

    })
}

export function getSharedWithOrgUnitsDO() {
    return getOrCreateDataObject({
        id: 'ds_o365_filters_sharedWith_Orgunits',
        viewName: 'sviw_Database_FiltersPermissions',
        uniqueTable: 'stbv_Database_FiltersPermissions',
        selectFirstRowOnLoad: true,
        allowInsert: true,
        allowUpdate: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey' },
            { name: 'Filter_Name' },
            { name: 'Filter_ID', type: 'number' },
            { name: 'OrgUnit_ID', type: 'number' },
            { name: 'OrgUnit_Name' },
            { name: 'Updated', type: 'datetime' },
            { name: 'Created', type: 'datetime' },
            { name: 'UpdatedBy_ID' },
            { name: 'CreatedBy_ID' },
            { name: 'IncludeSubOrgUnits', type: 'bit' },
        ]
    });
}

export function getReportFieldsDO(pReportID: string) {
    return getOrCreateDataObject({
        id: 'ds_o365_report_fields_' + pReportID,
        viewName: "sviw_Reporting_ReportsFields",
        fields: [{ name: "FieldName" }, { name: "DisplayName" }, { name: "DataType" }, { name: "ColumnLength" }],
        whereClause: `[ReportID] = '${pReportID}'`,
        disableLayouts: true,
        maxRecords: -1,
        dynamicLoading: false
    });
}

export function getViewFiltersDO(pViewName: string) {
    return getOrCreateDataObject({
        id: `ds_o365_filtersList_${pViewName}`,
        viewName: "sviw_Database_Filters",
        uniqueTable: "stbv_Database_Filters",
        fields: [{ name: "PrimKey" }, { name: "FilterName" },
        { name: "FilterCriteria" }, { name: "DBObjectID" }, { name: "ID", type: "number" },
        { name: "Mine", type: "bit" }, { name: "FilterTemplate" }, { name: "Hidden", type: "bit" },
        { name: "SharedPerson", type: "bit" }, { name: "SharedOrgUnit", type: "bit" }, {name:"AccessIdPath"},{name:"OrgUnit_ID"},{name:"SharedWithOrgUnit_IDs"}, {name:"SharedWithIdPaths"}],
        whereClause: "[DBObjectID] = '" + pViewName + "'",
        allowInsert: true,
        allowUpdate: true,
        allowDelete: true,
        disableLayouts: true,
    })
}